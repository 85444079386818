body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("https://c8.alamy.com/comp/2BKMKXB/tiny-baby-kittens-seamless-pattern-adorable-little-cats-background-simple-kawaii-doodle-style-2BKMKXB.jpg");
  overflow: hidden;
}

.book {
  display: flex;
  flex-direction: column;
  width: 90vw; /* Make it 90% of the viewport width */
  height: 120vh; /* Make it 80% of the viewport height */
  max-width: 700px; /* Set a maximum width for the book */
  max-height: 700px; /* Set a maximum height for the book */
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  perspective: 1000px;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;
  background-color: #fafafa;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: transform 0.6s;
}

.navigation {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
